import { Parameter } from './Types.ts';

interface ParameterInputProps {
  param: Parameter;
  selectedValue: string;
  onParameterSelection: (parameter: Parameter, selectedValue: string) => void;
  index: number;  // Der Index wird als separates Prop übergeben
}

const getInputType = (param: Parameter) => {
  if (param.schema?.type === 'integer') return 'number';
  if (param.schema?.format === 'date-time') return 'datetime-local';
  return 'text';
};

const handleInputValidation = (param: Parameter, value: string, currentValue: string) => {
  if (param.schema?.type === 'integer') {
    return value === "" || /^[0-9]+$/.test(value) ? value : currentValue;
  }
  return value;
};

export default function ParameterInput({ param, selectedValue, onParameterSelection, index }: ParameterInputProps) {
  // Nutze den übergebenen index, um die Hintergrundfarbe zu bestimmen
  const dropdownBackgroundColor = index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100';

  return param.schema?.enum ? (
    <div class="relative inline-block text-left">
      <select
        class={`border rounded-xl px-4 py-2 cursor-pointer w-full sm:w-auto ${dropdownBackgroundColor}`}
        value={selectedValue}
        onChange={(e) => onParameterSelection(param, (e.target as HTMLSelectElement).value)}
      >
        <option value=""></option>
        {param.schema.enum.map((value, idx) => (
          <option key={idx} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <input
      type={getInputType(param)}
      class={`border rounded-xl px-4 py-2 w-full sm:w-auto ${dropdownBackgroundColor}`}
      placeholder={`Enter ${param.schema?.type || 'value'}`}
      value={selectedValue}
      onInput={(e) =>
        onParameterSelection(param, handleInputValidation(param, (e.target as HTMLInputElement).value, selectedValue))
      }
    />
  );
}
